import { Inject, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Configuration } from 'app/shared/api';
import { CustomHttpParameterCodec } from 'app/shared/api/encoder';
import { API_V2_PATH } from 'app/shared/api-overrides/apiV2/variables';
import getv2range from 'app/shared/api-overrides/apiV2/getv2range';

type HttpPostParams = {
  headers?: HttpHeaders;
};

export class Apiv2Service {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(API_V2_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    this.configuration = configuration || new Configuration();
    if (typeof this.configuration.basePath !== 'string') {
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  protected httpGet<T>(url: string, queryParameters?: HttpParams): Observable<T> {
    const headers = this.getHeaders();

    return this.httpClient.get<T>(url, {
      params: queryParameters,
      responseType: 'json',
      withCredentials: this.configuration.withCredentials,
      headers,
      observe: 'body',
    });
  }

  protected httpPost<T>(
    url: string,
    body: Record<string, unknown> | FormData,
    { headers }: HttpPostParams = {}
  ): Observable<T> {
    return this.httpClient.post<T>(url, body, {
      responseType: 'json',
      withCredentials: this.configuration.withCredentials,
      headers: headers ?? this.getHeaders(),
      observe: 'body',
    });
  }

  protected httpPatch<T>(
    url: string,
    body: Record<string, unknown> | FormData,
    { headers }: HttpPostParams = {}
  ): Observable<T> {
    return this.httpClient.patch<T>(url, body, {
      responseType: 'json',
      withCredentials: this.configuration.withCredentials,
      headers: headers ?? this.getHeaders(),
      observe: 'body',
    });
  }

  protected createQueryParameters(params: Record<string, string | number>, page?: number, size?: number): HttpParams {
    let httpParams = new HttpParams({
      encoder: this.encoder,
      // !== undefined to handle 0
      ...((page ?? size) !== undefined
        ? {
            fromObject: {
              range: getv2range(page, size),
            },
          }
        : {}),
    });

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        httpParams = httpParams.append(key, value);
      }
    }

    return httpParams;
  }

  protected getHeaders(): HttpHeaders {
    return this.defaultHeaders
      .set('Authorization', `Bearer ${this.configuration.lookupCredential('bearer')}`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
  }
}
