// Based on : https://redfroggy.atlassian.net/wiki/spaces/S/pages/844791809/Matrice+des+droits

export enum RightName {
  READ_ANY_DOCUMENT_FORM = 'READ_ANY_DOCUMENT_FORM', // Visualiser n’importe quel formulaire de document
  READ_DOCUMENT_FORMS_OF_OWN_ORGANISATION_RESOURCE = 'READ_DOCUMENT_FORMS_OF_OWN_ORGANISATION_RESOURCE', // Visualiser les formulaires de document d’une resource de sa société
  READ_DOCUMENT_AUTO_CONTROL_DATA = 'READ_DOCUMENT_AUTO_CONTROL_DATA', // Visualiser les données de contrôle automatique du document
  LOCK_DOCUMENT = 'LOCK_DOCUMENT', // Verrouiller un document
  UNLOCK_DOCUMENT = 'UNLOCK_DOCUMENT', // Déverrouiller un document
  CREATE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE = 'CREATE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE', // Déposer un document pour une resource de sa société
  DELETE_ANY_DOCUMENT = 'DELETE_ANY_DOCUMENT', // Supprimer un document (archivé)
  DELETE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE = 'DELETE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE', // Supprimer un document d'une ressource de sa société (archivé)
  UPDATE_DOCUMENT_TO_RENEW_STATE = 'UPDATE_DOCUMENT_TO_RENEW_STATE', // Changer l'état d’un document (A renouveler)
  UPDATE_ANY_DOCUMENT_TO_CONTROL_STATE = 'UPDATE_ANY_DOCUMENT_TO_CONTROL_STATE', // Changer l'état  (A contrôler) de n’importe quel document
  UPDATE_DOCUMENT_TO_CONTROL_STATE_OF_OWN_ORGANISATION_RESOURCE = 'UPDATE_DOCUMENT_TO_CONTROL_STATE_OF_OWN_ORGANISATION_RESOURCE', // Changer l'état  (A contrôler) d’un document d’une resource de sa société
  UPDATE_DOCUMENT_TO_VALIDATED_STATE = 'UPDATE_DOCUMENT_TO_VALIDATED_STATE', // Changer l'état d’un document (Validé)
  UPDATE_DOCUMENT_REFUSED_STATE = 'UPDATE_DOCUMENT_REFUSED_STATE', // Changer l'état d’un document (Refusé)
  UPDATE_ANY_DOCUMENT_TO_COMPLETE_STATE = 'UPDATE_ANY_DOCUMENT_TO_COMPLETE_STATE', // Changer l'état  (A completer) de n’importe quel document
  UPDATE_DOCUMENT_TO_COMPLETE_STATE_OF_OWN_ORGANISATION_RESOURCE = 'UPDATE_DOCUMENT_TO_COMPLETE_STATE_OF_OWN_ORGANISATION_RESOURCE', // Changer l'état  (A completer) d’un document d’une resource de sa société
  UPDATE_DATAS_OF_ANY_DOCUMENTS = 'UPDATE_DATAS_OF_ANY_DOCUMENTS', // Modifier les données de n’importe quel document
  UPDATE_DATAS_OF_ANY_DOCUMENTS_FROM_ANY_DOCUMENT_STATUS = 'UPDATE_DATAS_OF_ANY_DOCUMENTS_FROM_ANY_DOCUMENT_STATUS',
  UPDATE_DATAS_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE = 'UPDATE_DATAS_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE', // Modifier les données des documents d’une resource de sa société
  UPDATE_FILES_OF_ANY_DOCUMENT = 'UPDATE_FILES_OF_ANY_DOCUMENT', // Ajouter des fichiers à n’importe quel document
  UPDATE_FILES_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE = 'UPDATE_FILES_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE', // Ajouter des fichiers à tous les documents
  CREATE_RESOURCE_FOR_OWN_COMPANY = 'CREATE_RESOURCE_FOR_OWN_COMPANY', // Ajouter une ressource pour sa société
  EDIT_ANY_RESOURCE = 'EDIT_ANY_RESOURCE', // Modifier n’importe quelle ressource
  EDIT_RESOURCE_OF_OWN_COMPANY = 'EDIT_RESOURCE_OF_OWN_COMPANY', // Modifier une ressource de sa société
  DELETE_ANY_RESOURCE = 'DELETE_ANY_RESOURCE', // Supprimer une ressource
  DELETE_RESOURCE_OF_OWN_COMPANY = 'DELETE_RESOURCE_OF_OWN_COMPANY', // Supprimer une ressource de sa société
  INVITE_ORGANISATION = 'INVITE_ORGANISATION', // Inviter (créer) un fournisseur
  CREATE_ORGANISATION = 'CREATE_ORGANISATION', // Créer une organisation
  EDIT_ANY_ORGANISATION = 'EDIT_ANY_ORGANISATION', // Modifier n’importe quelle organisation
  EDIT_OWN_ORGANISATION = 'EDIT_OWN_ORGANISATION', // Modifier son organisation
  EDIT_BTPCARD_REQUIRED_ANY_ORGANISATION = 'EDIT_BTPCARD_REQUIRED_ANY_ORGANISATION', // Modifier BTP Card required d'une organisation
  EDIT_TEMPORARY_EMPLOYMENT_ANY_ORGANISATION = 'EDIT_TEMPORARY_EMPLOYMENT_ANY_ORGANISATION', // Modifier valeur temporary employment d'une organisation
  ACCEPT_ANY_OPERATION_INVITATION = 'ACCEPT_ANY_OPERATION_INVITATION', // Rejoindre une opération (invitation reçue) => accepter n’importe quelle invitation
  ACCEPT_OPERATION_INVITATION_FOR_OWN_ORGANISATION = 'ACCEPT_OPERATION_INVITATION_FOR_OWN_ORGANISATION', // Accepter une invitation pour sa société
  ADD_ANY_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION = 'ADD_ANY_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION', // Affecter n’importe quelle ressource à un chantier de la société de l’employée
  ADD_OWN_ORGANISATION_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION = 'ADD_OWN_ORGANISATION_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION', // Affecter n’importe quelle ressource à un chantier de la société de l’employée
  CREATE_OPERATION_FOR_ANY_ORGANISATION = 'CREATE_OPERATION_FOR_ANY_ORGANISATION', // Créer une opération pour n’importe qu’elle organisation,
  CREATE_OPERATION_FOR_OWN_ORGANISATION = 'CREATE_OPERATION_FOR_OWN_ORGANISATION', // Créer une opération pour son organisation,
  EDIT_ANY_OPERATION = 'EDIT_ANY_OPERATION', // Modifier n’importe quelle opération
  EDIT_ANY_OPERATION_IF_IS_CLIENT = 'EDIT_ANY_OPERATION_IF_IS_CLIENT', // Modifier n’importe quelle opération si DO
  EDIT_OWN_OPERATION_IF_IS_CLIENT = 'EDIT_OWN_OPERATION_IF_IS_CLIENT', // Modifier ses opération si DO
  CREATE_USER_FOR_ANY_ORGANISATION = 'CREATE_USER_FOR_ANY_ORGANISATION', // Créer un utilisateur pour n’importe quelle organisation
  CREATE_USER_FOR_OWN_ORGANISATION = 'CREATE_USER_FOR_OWN_ORGANISATION', // Créer un utilisateur pour son organisation
  CREATE_ADMIN_OR_SUPERADMIN_USER = 'CREATE_ADMIN_OR_SUPERADMIN_USER', // Créer un utilisateur admin ou superadmin
  EDIT_ANY_USER = 'EDIT_ANY_USER', // Modifier un utilisateur
  ADD_USER_TO_ANY_OPERATION = 'ADD_USER_TO_ANY_OPERATION', // Affecter un utilisateur à n’importe quelle opération
  ADD_USER_TO_OPERATION_OF_OWN_ORGANISATION = 'ADD_USER_TO_OPERATION_OF_OWN_ORGANISATION', // Affecter un utilisateur à une opération de son organisation
  EDIT_INVITATION_DATES = 'EDIT_INVITATION_DATES', // Editer les dates d’une invitation
  CAN_NAVIGATE = 'CAN_NAVIGATE', // naviguer dans les principales pages (le role ACCESS_CONTROL est limité)
  DISABLE_EMAIL_NOTIFICATION_DOCUMENTSTATUS_TOCONTROL = 'DISABLE_EMAIL_NOTIFICATION_DOCUMENTSTATUS_TOCONTROL', // avoir dans les préférences utilisateur la notification pour les dossiers status to_control pour désactiver
  SITE_CONTROL_ANY_RESOURCE = 'SITE_CONTROL_ANY_RESOURCE', // Effectuer un contrôle sur site de n'importe quelle resource
  EXPORT_ANY_PROJECT_RESOURCES = 'EXPORT_ANY_PROJECT_RESOURCES', // Effectuer un export des ressources pour n'importe quel projet
  CAN_SEE_RESOURCES_OBLIGATIONS = 'CAN_SEE_RESOURCES_OBLIGATIONS',
  CAN_SEE_RESOURCES_BO_LINKS = 'CAN_SEE_RESOURCES_BO_LINKS',
}
